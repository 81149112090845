@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
.text--gray {
  color: #e6e8ea; }

.text--dark-gray {
  color: #a1afbb; }

.text--dark-gray-2 {
  color: #546e7e; }

.text--light-blue {
  color: #179dfe; }

.text--white {
  color: #ffffff; }

.text-highlight {
  background-color: #f9e4b8; }

:root {
  font-size: 16px; }

/**
 * h1 untuk header superbesar (28sp)
 * Contoh: Verifikasi email
 *
 * h2 untuk header login atau signup (20sp)
 * Contoh: Selamat bergabung dengan Populix
 * 
 * h3 untuk header welcome user (16sp)
 * Contoh: Hi, Juinal. Selamat datang.
 * 
 * h4 untuk header activity (15sp)
 * Contoh: Studi marketplace tentang pengguna teknologi
 *
 * h5 untuk nama perusahaan dari activity (14sp)
 * Contoh: Tokopedia, East Ventures, dll
 *
 * h6 untuk header warna grey (12sp)
 * Contoh: Aktivitas yang bisa kamu lakukan, Riwayat Aktivitas, dll
 *
 * p umumnya digunakan yang lain-lain (13sp)
 * Contoh: subtitle, konten, dll
 *
 * class f-12 untuk p atau span atau label ukuran 12sp
 */
h1 {
  font-size: 1.75rem; }

h2 {
  font-size: 1.25rem; }

h3 {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 700;
  margin: 0; }

h4 {
  font-size: 0.93rem; }

h5 {
  font-size: 0.87rem; }

h6 {
  font-size: 0.75rem;
  color: #a1afbb;
  font-weight: 600;
  line-height: 1rem; }

p,
label {
  font-size: 0.81rem;
  line-height: 20px;
  font-weight: 400; }

.f-12 {
  font-size: 0.75rem; }

.f-13 {
  font-size: 0.81rem !important; }

.f-16 {
  font-size: 0.95rem; }

.f-1rem {
  font-size: 1rem; }

.f-125rem {
  font-size: 1.25rem; }

* {
  font-family: 'Open Sans', sans-serif; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-semibold {
  font-weight: 600; }

.font-bold {
  font-weight: 700; }

.font-extrabold {
  font-weight: 800; }

.text--middle {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #edeff2;
  line-height: 0.1rem;
  margin: 26px 0 26px; }
  .text--middle span {
    background-color: #ffffff;
    padding: 0 12px; }

.text-color--danger {
  color: #d33a27; }

.text-color--warning {
  color: #faaf40; }

.text-color--base {
  color: #0d2433; }

.text-color--primary {
  color: #179dfe; }

.text-color--greyedout {
  color: #a1afbb; }

.text-color--success {
  color: #3ccd90; }

.text--elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

html,
body {
  height: 100%; }

a {
  cursor: pointer; }

@media screen and (max-width: 767.98px) {
  .container {
    padding: 32px 24px 76px 24px;
    height: 100%; }
  .mobile-disabled {
    display: none; } }

@media screen and (min-width: 768px) {
  .container {
    padding: 0;
    margin: 0; }
  .desktop-disabled {
    display: none; }
  .signup .topnav,
  .signup .body-content,
  .login .topnav,
  .login .body-content,
  .prescreening .topnav,
  .prescreening .body-content {
    padding: 16px 10% 16px 10% !important; } }

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1440px; }
    .container:not(.login):not(.signup):not(.prescreening) {
      margin: 0 auto; }
  .body-content {
    padding-right: 24px; } }

.navbar,
.navbar-collapse,
.navbar-toggler {
  padding: 0; }

.navbar {
  padding-left: 0 !important; }

.row,
.col {
  padding: 0;
  margin: 0; }

i.fas,
i.fab {
  background-color: transparent; }
  i.fas.fa-arrow-right,
  i.fab.fa-arrow-right {
    color: #ffffff;
    vertical-align: middle !important; }

.nav-link {
  color: #0d2433;
  font-weight: 500;
  margin: 2px 0;
  font-weight: 400; }

.nav-link.active,
.nav-link:hover {
  color: #179dfe !important; }

.topnav {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  width: 100%;
  align-items: center; }
  .topnav .nav-text {
    justify-self: end; }
  .topnav p {
    margin: 0; }

.topnav-notif-avatar {
  vertical-align: middle;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 8px; }
  .topnav-notif-avatar .topnav-notif__avatar--size {
    width: 26px;
    height: 26px; }
  .topnav-notif-avatar .topnav-notif__avatar--radius-100 {
    border-radius: 100%; }
  .topnav-notif-avatar #topnav-btn-avatar {
    background-size: 26px 26px;
    background-repeat: no-repeat; }
  .topnav-notif-avatar button {
    border: 0px;
    background-color: #edeff2;
    border-radius: 50px;
    width: 26px;
    height: 26px; }
    .topnav-notif-avatar button i {
      font-size: 12px;
      color: #a1afbb; }

@media screen and (min-width: 768px) {
  .topnav-notif-avatar {
    grid-column-gap: 24px; }
    .topnav-notif-avatar .topnav-notif__avatar--size {
      width: 40px;
      height: 40px; }
    .topnav-notif-avatar .topnav-notif__avatar--radius-100 {
      border-radius: 100%; }
    .topnav-notif-avatar #topnav-btn-avatar {
      cursor: pointer;
      background-size: 40px 40px; }
    .topnav-notif-avatar button {
      width: 40px;
      height: 40px; }
      .topnav-notif-avatar button i {
        font-size: 1.2rem; } }

@media screen and (max-width: 768px) {
  .no-wrap {
    flex-wrap: nowrap; }
  .scroll-enabled {
    overflow-x: auto; }
  .no-scrollbar::-webkit-scrollbar {
    display: none; }
  .nav-link--flex {
    display: flex;
    flex: 0 0 auto; } }

/*
 * Mobile View (Less than 768px)
 */
.grid-2 {
  display: grid;
  grid-template-columns: 40px auto;
  grid-row-gap: 17px; }

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 3fr;
  grid-row-gap: 17px; }

.dropdown-menu a {
  display: grid;
  grid-template-columns: 50px 1fr; }

/*
* Desktop View Grid Layout
*/
@media screen and (min-width: 768px) {
  .topnav {
    grid-area: topnav;
    padding: 32px 32px 0 0; }
  .tab {
    grid-area: tab; }
  .sidebar {
    grid-area: sidebar;
    padding: 32px 0 76px 32px;
    height: 100%; }
  .body-content {
    grid-area: body;
    padding-bottom: 76px; }
  .container:not(.login):not(.signup):not(.prescreening) {
    display: grid;
    grid-template-columns: 220px auto;
    grid-template-rows: 0 70px auto;
    grid-template-areas: "sidebar topnav" "sidebar tab" "sidebar body";
    grid-column-gap: 49px; }
  .body1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: ". floating-card";
    grid-column-gap: 40px; }
    .body1 .floating-card {
      grid-area: floating-card;
      max-width: 220px !important;
      width: 220px;
      justify-self: end; }
  .login .topnav,
  .signup .topnav,
  .prescreening .topnav {
    border-bottom: 1px solid #edeff2;
    padding-bottom: 20px; }
  .login .body-content,
  .signup .body-content,
  .prescreening .body-content {
    padding: 0 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5%; }
    .login .body-content .login-header,
    .login .body-content .signup-header,
    .login .body-content .prescreening-header,
    .signup .body-content .login-header,
    .signup .body-content .signup-header,
    .signup .body-content .prescreening-header,
    .prescreening .body-content .login-header,
    .prescreening .body-content .signup-header,
    .prescreening .body-content .prescreening-header {
      grid-area: head; }
    .login .body-content .login-list,
    .login .body-content .signup-list,
    .login .body-content .prescreening-form,
    .signup .body-content .login-list,
    .signup .body-content .signup-list,
    .signup .body-content .prescreening-form,
    .prescreening .body-content .login-list,
    .prescreening .body-content .signup-list,
    .prescreening .body-content .prescreening-form {
      grid-area: body; }
  .signup .body-content {
    grid-template-areas: "hero hero" "head body"; }
  .signup .signup-hero {
    grid-area: hero;
    justify-self: center;
    width: 100%; }
  .login .body-content {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "header notif" "header form"; }
    .login .body-content .login-header {
      grid-area: header; }
    .login .body-content .notification-box {
      grid-area: notif; }
    .login .body-content .login-form {
      grid-area: form; }
  .prescreening .body-content {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "pre-header pre-form"; }
    .prescreening .body-content .prescreening-header {
      grid-area: pre-header; }
    .prescreening .body-content .prescreening-form {
      grid-area: pre-form; } }

@media screen and (max-width: 767.98px) {
  .navbar-collapse {
    position: absolute;
    top: 0;
    width: 90%;
    background-color: #ffffff !important;
    height: 100vh; }
  .navbar-collapse.show {
    box-shadow: 200px -10px rgba(13, 36, 51, 0.6); }
  .sidebar {
    padding: 32px 0 76px 24px;
    z-index: 998; }
  .sidebar-nav li:nth-child(1) {
    margin-top: 24px; }
  .sidebar-nav li a {
    font-size: 0.93rem; }
  .sidebar-nav .dropdown-menu {
    border: 0px;
    padding: 0; }
    .sidebar-nav .dropdown-menu a {
      font-size: 0.81rem;
      margin: 10px 0;
      padding-left: 0;
      color: #0d2433; }
      .sidebar-nav .dropdown-menu a:hover, .sidebar-nav .dropdown-menu a:active {
        color: #179dfe; }
      .sidebar-nav .dropdown-menu a img {
        padding: 0 15px 0 10px;
        width: 100%;
        height: auto;
        align-self: center;
        justify-self: center; }
      .sidebar-nav .dropdown-menu a span {
        align-self: center;
        justify-self: start; }
  /* Kondisi awal */ }

#app-navbar {
  /* Position and sizing of burger button */
  /* Color/shape of burger icon bars */
  /* Position and sizing of clickable cross button */
  /* Color/shape of close button cross */
  /* General sidebar styles */
  /* Morph shape necessary with bubble or elastic */
  /* Individual item */
  /* Styling of overlay */ }
  #app-navbar #populix-logo {
    height: auto;
    margin-top: 30px; }
  #app-navbar .bm-burger-button {
    height: 20px !important;
    width: 30px !important; }
    #app-navbar .bm-burger-button span {
      position: absolute;
      width: inherit;
      height: inherit;
      top: inherit; }
    #app-navbar .bm-burger-button button {
      left: inherit !important;
      top: inherit !important;
      width: inherit !important;
      height: inherit !important; }
  #app-navbar .bm-burger-bars {
    background: #373a47; }
  #app-navbar .bm-cross-button {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 25px !important;
    top: 55px !important; }
  #app-navbar .bm-cross {
    background: #0d2433; }
  #app-navbar .bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em; }
  #app-navbar .bm-menu-wrap {
    left: 0;
    margin-top: -60px; }
  #app-navbar .bm-morph-shape {
    fill: #373a47; }
  #app-navbar .bm-item {
    display: inline-block;
    color: #0d2433;
    font-size: 0.81rem;
    margin: 10px 0; }
  #app-navbar .bm-overlay {
    box-shadow: 200px -10px rgba(13, 36, 51, 0.6);
    margin-top: -60px; }

@media screen and (min-width: 768px) {
  .sidebar {
    background-color: #f7f7f7;
    padding-right: 32px; }
    .sidebar .sidebar-profile {
      margin: 40px 0 48px;
      width: 95%; }
      .sidebar .sidebar-profile .sidebar-img-profile {
        width: 72px;
        height: 72px;
        border-radius: 50px;
        margin-left: 24px; }
      .sidebar .sidebar-profile .sidebar-profile-content {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
        padding: 50px 20px 20px;
        margin-top: -36px; }
        .sidebar .sidebar-profile .sidebar-profile-content .sidebar-profile-content__name-container {
          overflow: hidden;
          text-overflow: ellipsis; }
        .sidebar .sidebar-profile .sidebar-profile-content h4 {
          margin-bottom: 29px; }
  #app-navbar .bm-burger-button {
    display: none !important; } }

#populix-logo {
  width: 100px;
  height: auto;
  position: absolute;
  top: 30px; }

#app-navbar {
  /* Wrapper for item list */ }
  #app-navbar .bm-item-list {
    border: 0;
    padding: 0; }
    #app-navbar .bm-item-list img {
      padding: 0 15px 0 0px;
      height: 1rem;
      align-self: center;
      justify-self: center; }
    #app-navbar .bm-item-list a:nth-child(1) {
      margin-top: 54px; }

/* Button Primary for Blue Colored Button */
.btn-primary, .btn-secondary, .btn--orange, .btn--orange:hover {
  border: 0px;
  padding: 0.75rem 1.12rem 0.87rem;
  border-radius: 5px;
  font-size: 0.93rem;
  background-color: #179dfe;
  width: 50%;
  margin-top: 0; }
  .btn-primary:not(.modal-footer-btn):not(.btn-facebook):not(.btn-linkedin):not(.btn-google):not(.btn-linkedin), .btn-secondary:not(.modal-footer-btn):not(.btn-facebook):not(.btn-linkedin):not(.btn-google):not(.btn-linkedin), .btn--orange:not(.modal-footer-btn):not(.btn-facebook):not(.btn-linkedin):not(.btn-google):not(.btn-linkedin) {
    margin-top: 32px; }
  .btn-primary:disabled, .btn-secondary:disabled, .btn--orange:disabled {
    background-color: #edeff2;
    border-color: #edeff2; }
  .btn-primary--disabled {
    background-color: #b9b4b4 !important; }

.modal-btn {
  width: 75%;
  position: absolute; }

.btn-secondary {
  background-color: #f9fafc;
  border: 1px solid #a1afbb;
  color: #0d2433;
  margin-top: 0; }

.modal-footer-btn {
  width: inherit; }

.btn--orange {
  background-color: #faaf40;
  color: #ffffff; }

.btn--orange:hover {
  background-color: #faaf40;
  color: #ffffff !important; }

.btn-facebook, .btn-linkedin {
  background-color: #475993;
  margin: 16px 0; }
  .btn-facebook span, .btn-linkedin span,
  .btn-facebook i,
  .btn-linkedin i {
    color: #ffffff !important; }
  
  .btn-facebook i,
  .btn-linkedin i {
    padding-right: 7px; }
  .btn-facebook span, .btn-linkedin span {
    font-weight: 700; }

.btn-google {
  background-color: #ffffff;
  border: 2px solid #000;
  margin: 16px 0; }
  .btn-google img {
    max-width: 24px;
    padding-right: 7px;
    background-color: transparent; }
  .btn-google span {
    font-weight: 700; }

.btn-linkedin {
  background-color: #0077b7; }

/* Button berbentuk text */
.btn--text, button.navbar-toggler {
  background-color: transparent !important;
  border: 0px;
  cursor: pointer; }

.btn-block {
  width: 100%; }

/* Button navbar toggler */
button.navbar-toggler {
  padding-left: 0; }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e8ea;
    transition: 0.4s; }
    .switch .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: #ffffff;
      transition: 0.4s; }

input:checked + .slider {
  background-color: #faaf40; }

input:focus + .slider {
  box-shadow: 0 0 1px #faaf40; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.btn-file {
  position: relative;
  overflow: hidden;
  margin: 0 10px 0 0;
  padding: 0; }
  .btn-file input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    filter: alpha(opacity=0);
    opacity: 0;
    display: block;
    cursor: pointer !important; }

/*
 * Desktop View (More than 768px)
 */
@media screen and (min-width: 768px) {
  .btn-primary, .btn-secondary, .btn--orange, .btn--orange:hover {
    margin: 0; }
  .modal-btn {
    width: 20%;
    position: absolute; }
  .modal-footer-btn--line-height-0 {
    line-height: 0; } }

.btn-rekening {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  width: auto; }

select:not(.settings__option),
input[type='date']:not(.settings__input),
.screening-multioption,
.prescreening__option {
  border: none;
  font-weight: 700;
  color: #179dfe;
  background-color: transparent; }
  select:not(.settings__option)::-webkit-input-placeholder, input[type='date']:not(.settings__input)::-webkit-input-placeholder, .screening-multioption::-webkit-input-placeholder, .prescreening__option::-webkit-input-placeholder {
    color: #179dfe; }
  select:not(.settings__option)::placeholder,
  input[type='date']:not(.settings__input)::placeholder,
  .screening-multioption::placeholder,
  .prescreening__option::placeholder {
    color: #179dfe; }

.screening-multioption {
  height: auto; }
  .screening-multioption .screening-multioption__dropdown-indicator {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #179dfe; }

.prescreening__option--border-solid {
  border: 1px solid #e0e0e0;
  border-radius: 4px; }
  .prescreening__option--border-solid:hover, .prescreening__option--border-solid.selected {
    border: 2px solid #179dfe; }

.prescreening__option--font-normal {
  font-weight: 400;
  color: #4f4f4f; }

.prescreening__option--selected {
  border: 2px solid #179dfe; }

.prescreening__option--w-100 {
  width: 100%; }

.prescreening__option .prescreening-asyncoptions__dropdown-indicator {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #179dfe; }

.settings__question,
.signup__question {
  font-weight: 700;
  margin-bottom: 12px; }

.settings__input,
.settings__option,
.signup__input,
.login__input,
.form-password {
  height: 48px;
  border: 2px solid #e6e8ea;
  border-radius: 4px;
  font-size: 0.81rem;
  margin-bottom: 24px; }
  .settings__input.danger,
  .settings__option.danger,
  .signup__input.danger,
  .login__input.danger,
  .form-password.danger {
    border-color: #d33a27; }

.settings__validation-message {
  position: absolute;
  bottom: 0; }
  .settings__validation-message.\--right {
    right: 5px; }

.settings__input-border--danger {
  border-color: #d33a27; }

.form-password {
  border-right: 0px; }

.input-group-text {
  height: 48px;
  background-color: transparent;
  border: 2px solid #e6e8ea;
  border-left: 0px; }
  .input-group-text.danger {
    border-color: #d33a27; }
  .input-group-text .fa-eye,
  .input-group-text .fa-eye-slash {
    color: #d7dadb; }

.code-input {
  width: 100%;
  text-align: center; }
  .code-input input[type='number'] {
    font-family: monospace;
    border-radius: 6px;
    border: 1px solid lightgrey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    margin: 4px;
    padding-left: 8px;
    width: 36px;
    height: 42px;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    background-color: white; }
  .code-input input[type='number']::-webkit-inner-spin-button,
  .code-input input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none; }

/* Popover */
.popover-body div {
  padding: 10px 0;
  border-bottom: 1px solid #edeff2;
  /* Notif */ }
  .popover-body div .notif-date {
    padding-right: 5px; }

.popover-body li {
  font-size: 13px;
  padding: 0 24px;
  line-height: 30px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.popover-body li:nth-child(last) {
  padding-bottom: 0; }

.popover-body a {
  text-decoration: none;
  color: #000;
  line-height: 20px; }

.popover-body li:hover {
  background-color: #f9fafc; }

.popover-body hr {
  padding: 0; }

/* Hide Popover Content */
.popover-content-hidden {
  display: none; }

div .popup-content {
  border-radius: 8px;
  padding: 0 !important; }

div .popover-body {
  padding: 12px 0 0 0; }
  div .popover-body .popover-body--no-effect {
    cursor: default; }
    div .popover-body .popover-body--no-effect:hover {
      background-color: transparent; }

.popover-background {
  background-color: #10496d;
  color: white;
  padding: 10px 15px;
  text-align: justify;
  font-size: 12px;
  border-radius: 5px; }

.progress, .progress-bar {
  border-radius: 10px; }
  .progress.progress--05, .progress--05.progress-bar {
    height: 0.5rem; }

.progress-bar {
  background-color: #faaf40; }

/*
* Mobile View (Less than 768px)
*/
.tab {
  font-size: 0.81rem;
  line-height: 20px; }
  .tab .nav-tabs {
    border: 0px;
    border-bottom: 1px solid #edeff2;
    margin-bottom: 24px; }
    .tab .nav-tabs .nav-link {
      border: 0px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 12px;
      margin: 32px 20px -1px 0;
      font-size: 0.95rem;
      font-weight: 400; }
      .tab .nav-tabs .nav-link .icon {
        width: 15px;
        margin-right: 5px;
        vertical-align: center; }
      .tab .nav-tabs .nav-link.active {
        border-bottom: 1px solid #179dfe; }

.history-list h6 {
  margin-top: 48px; }

.history-list p {
  margin-bottom: 0;
  color: #10496d; }

.history-list .history-item {
  border-bottom: 1px solid #edeff2;
  padding: 35px 0 28px; }
  .history-list .history-item .history__icon {
    max-width: 35px;
    max-height: 30px;
    margin-bottom: 15px; }

.history-list .history-footer {
  margin-top: 24px; }

/*
* Desktop View (More than 768px)
*/
@media screen and (min-width: 768px) {
  .tab .nav-tabs a {
    margin-top: 25px;
    padding-top: 0; }
  .history-list h6 {
    margin-top: 0; }
  .history-list .history-item {
    display: grid;
    grid-template-columns: 50px auto auto;
    grid-column-gap: 20px;
    grid-template-areas: 'img . date'; }
    .history-list .history-item img {
      grid-area: img; }
    .history-list .history-item .history__date {
      grid-area: date;
      justify-self: end; } }

@media screen and (max-width: 768px) {
  .tab .nav-tabs .nav-link {
    margin: 32px 17px -1px 0; } }

.modal-header {
  align-items: center; }
  .modal-header button {
    font-size: 35px;
    color: #c9ccce !important; }

.modal-footer {
  padding-top: 0;
  border: 0; }

.modal-footer-type-1 {
  display: block;
  text-align: center; }
  .modal-footer-type-1 .modal-footer-btn {
    text-align: center;
    width: inherit; }

.modal-footer-type-2 {
  display: flex;
  justify-content: flex-end !important; }

.modal-backdrop {
  background-color: rgba(13, 36, 51, 0.6); }

.modal-backdrop-transparent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -1000; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }
  .modal .modal-main {
    position: fixed;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .modal.modal--white {
    background: white; }

.display-block {
  display: block; }

.display-none {
  display: none; }

@media screen and (max-width: 768px) {
  .modal {
    background-color: white; }
    .modal .modal-main {
      width: 100%; }
  .modal-dialog-centered {
    align-items: flex-start; }
  .modal-content {
    border: none; } }

/*
 * Mobile View (Less than 768px)
 */
.study-list .icon,
.study-details-and-reward .icon,
.survey-header__time .icon {
  max-width: 24px;
  max-height: 24px;
  align-self: center;
  justify-self: center; }

.study-list .survey-header__value,
.study-details-and-reward .survey-header__value,
.survey-header__time .survey-header__value {
  margin: 0;
  align-self: center;
  justify-self: start; }

.study-icon {
  max-width: 42px;
  max-height: 42px;
  margin: 0 0 26px; }

.small-icon {
  max-width: 25px;
  max-height: 25px; }

.smaller-icon {
  max-width: 20px;
  max-height: 20px; }

.img-3-dots {
  margin: 24px 0 24px 3px; }

.img-lg {
  max-width: 50%; }

.floating-card .progress-card {
  margin-top: 24px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08); }
  .floating-card .progress-card .progress, .floating-card .progress-card .progress-bar, .floating-card .progress-card .progress-bar {
    height: 10px;
    margin-bottom: 12px; }

.floating-card .progress-screening-list {
  padding-bottom: 17px; }
  .floating-card .progress-screening-list .progress-screening-item {
    padding: 17px 0;
    border-bottom: 1px solid #edeff2; }
    .floating-card .progress-screening-list .progress-screening-item span {
      font-size: 0.9rem;
      line-height: 1.33em; }

.skeletons.form-skeleton__children {
  width: 100%;
  height: 26px;
  background-color: #c9ccce;
  margin-bottom: 1.5rem; }
  .skeletons.form-skeleton__children:nth-child(2) {
    width: 80%; }
  .skeletons.form-skeleton__children:nth-child(3) {
    width: 50%; }

.skeletons.form-skeleton__profile-picture {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-bottom: 1.5rem; }

.skeletons.study-item-skeleton__icon {
  background-color: #c9ccce;
  width: 42px;
  height: 42px;
  border-radius: 50%; }

.skeletons.study-item-skeleton__popcorn {
  width: 6%;
  height: 180px; }

@media screen and (max-width: 768px) {
  .form-skeleton__container {
    margin-top: 25%; } }

.pop-badge {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.625rem;
  max-width: 100px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  margin-top: 8px;
  color: #ffffff;
  cursor: default;
  border-radius: 40px;
  font-weight: 700; }
  .pop-badge.pop-badge--success {
    background-color: #3ccd90; }
  .pop-badge.pop-badge--pending {
    background-color: #faaf40; }
  .pop-badge.pop-badge--rejected {
    background-color: #f14336; }

@media screen and (min-width: 320px) and (max-width: 360px) {
  .pop-badge {
    font-size: 0.475rem;
    padding: 5px; } }

@media screen and (min-width: 360px) and (max-width: 375px) {
  .pop-badge {
    padding: 5px 10px; } }

@media screen and (min-width: 375px) and (max-width: 768px) {
  .pop-badge {
    font-size: 0.575rem;
    padding: 5px; } }

.bank-account-list {
  display: flex;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
  padding: 8px 15px;
  align-items: center;
  font-size: 0.81rem;
  line-height: 20px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  cursor: default; }

.bank-account__detail {
  align-items: center;
  width: 55%; }

.bank-account__detail-user {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700; }

.bank-account__panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%; }

.bank-account__panel-action {
  display: flex;
  margin: 0 0 0 10px;
  align-items: center;
  padding: 0 8px;
  border-left: solid 0.5px #e0e0e0; }

.bank-account__panel-item,
.bank-account__panel-link {
  margin-left: 8px;
  font-weight: 400;
  cursor: pointer; }

.bank-account__panel-item:hover,
.bank-account__panel-link:hover {
  color: #179dfe !important; }

.bank-account__panel .pop-badge {
  margin-top: 0;
  padding: 2px 20px; }

.stepper__root {
  height: 83vh; }
  .stepper__root .stepper__footer {
    display: flex;
    justify-content: flex-end; }

.stepper__context .bank-account-settings-form__img-wrapper {
  padding: 1rem 3rem; }

.stepper__context .f-13 {
  font-size: 16px !important; }

.stepper__context .phone-verification .btn-sm {
  padding: 0.15rem 0.5rem; }

.stepper__context .phone-verification--mb0 {
  margin-bottom: 0; }

@media screen and (min-width: 1200px) {
  .stepper__root {
    padding-right: 6rem; }
  .stepper__context {
    padding: 3rem 5rem; }
    .stepper__context .bank-account-settings-form__img-wrapper {
      padding: 1rem 15rem; } }

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer; }

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%; }

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196F3; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white; }

.phone-verification {
  display: flex; }
  .phone-verification__link-button {
    cursor: pointer; }

.dailyquestion__question {
  font-size: 14px; }

.dailyquestion__btn {
  margin-top: 12px !important;
  width: 113.95px;
  height: 70%; }

.dailyquestion__progressbar-label {
  font-size: 12px;
  float: right;
  top: -34.5px;
  position: relative;
  margin-right: 4px; }

.dailyquestion__result-label {
  margin-bottom: 4px; }

.dailyquestion__answer {
  padding-top: 2px; }

.ticker__panel {
  margin: 1rem 2rem 1rem 2rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem; }
  .ticker__panel h3 {
    text-align: center;
    margin-bottom: 2px; }

.ticker__warning {
  background-color: #ffffcc; }

@media screen and (min-width: 1200px) {
  .ticker__panel {
    margin-right: 15rem;
    margin-left: 10rem; } }

/*
 * Mobile View (Less than 768px)
 */
.dashboard .body-content {
  margin: 54px 0 0; }

.dashboard .dashboard-header {
  margin: 0 0 40px; }
  .dashboard .dashboard-header p {
    margin: 0; }

.dashboard .study-item {
  margin: 32px 0;
  padding: 0 0 32px;
  border-bottom: 1px solid #edeff2; }

/*
 * Desktop View (More than 768px)
 */
@media screen and (min-width: 768px) {
  .dashboard {
    height: 100%; }
    .dashboard .body-content {
      margin: 0; }
      .dashboard .body-content .img-3-dots {
        margin: 32px 0; }
      .dashboard .body-content .floating-card {
        margin-top: 64px; }
      .dashboard .body-content .dashboard-activity {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'title .' 'desc button'; }
        .dashboard .body-content .dashboard-activity h4 {
          grid-area: title; }
        .dashboard .body-content .dashboard-activity p {
          grid-area: desc; }
        .dashboard .body-content .dashboard-activity a {
          grid-area: button;
          text-align: right; }
      .dashboard .body-content .study-item {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-column-gap: 30px; }
        .dashboard .body-content .study-item a.btn-primary, .dashboard .body-content .study-item a.btn-secondary, .dashboard .body-content .study-item a.btn--orange {
          justify-self: end;
          width: 100%; }
        .dashboard .body-content .study-item button {
          min-width: 115px; } }

.login .body-content {
  margin: 48px 0; }

.login .login-header {
  margin-bottom: 28px; }

.login .notification-box p {
  background-color: #fff3da;
  padding: 0.75rem 1rem; }

.login .form-password {
  border-right: 2px solid #e6e8ea; }
  .login .form-password.danger {
    border-right: 2px solid #d33a27; }

/*
* Desktop View
*/
@media screen and (min-width: 768px) {
  .login-btn-socmed {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 12px; } }

/*
 * Mobile View (Less than 768px)
 */
#screening .header-screening-cluster {
  margin: 54px 0 16px;
  border-bottom: 1px solid #edeff2; }

#screening .section-screening__icon {
  max-width: 30px; }

#screening .section-screening {
  margin: 16px 0;
  border-bottom: 1px solid #edeff2;
  padding-bottom: 16px; }
  #screening .section-screening img {
    align-self: start;
    justify-self: start; }
  #screening .section-screening form {
    align-self: center;
    justify-self: start;
    font-size: 0.81rem; }

/*
 * Desktop View (More than 768px)
 */
@media screen and (min-width: 768px) {
  .screening {
    height: 100%; }
    .screening .header-screening-cluster {
      margin: 0 0 16px; }
    .screening .section-screening:nth-child(1) {
      margin-top: 0; } }

#prescreening .body-content {
  margin: 48px 0; }

#prescreening .prescreening-header h2 {
  margin-top: 24px; }

#prescreening .prescreening-icon img {
  max-width: 24px; }

#prescreening .prescreening-progress {
  margin: 32px 0; }
  #prescreening .prescreening-progress .progress-detail {
    text-align: center; }

#prescreening #prescreening-Gender .prescreening__option .col:nth-child(1) {
  margin-right: 10px !important; }

#prescreening #prescreening-Gender .prescreening__option input {
  display: none;
  margin: 0;
  padding: 0; }

#prescreening #prescreening-Gender .prescreening__option label {
  cursor: pointer;
  background-size: contain;
  border: 5px solid #edeff2;
  border-radius: 5px;
  padding-top: 28px;
  padding-bottom: 28px;
  width: 100%; }
  #prescreening #prescreening-Gender .prescreening__option label:hover {
    border: 5px solid #179dfe; }

#prescreening #prescreening-Gender .prescreening__option input:checked + label {
  border: 5px solid #179dfe; }

#prescreening .prescreening-id-card {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  #prescreening .prescreening-id-card p {
    margin: 0 0 0 24px; }

#prescreening .prescreening__ktp {
  opacity: 0;
  position: absolute; }

#prescreening .prescreening__ktp-height {
  height: 30vh; }

#prescreening .prescreening-footer {
  margin-top: 32px;
  border-top: 1px solid #edeff2;
  padding-top: 24px; }
  #prescreening .prescreening-footer .prescreening-footer-btn {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    #prescreening .prescreening-footer .prescreening-footer-btn a.btn-primary, #prescreening .prescreening-footer .prescreening-footer-btn a.btn-secondary, #prescreening .prescreening-footer .prescreening-footer-btn a.btn--orange {
      margin-top: 0 !important; }

#prescreening .prescreening__upload-btn-master {
  position: absolute;
  left: 0;
  opacity: 0; }

#prescreening .prescreening__upload-btn-custom {
  border: transparent;
  font-size: 1rem;
  color: #179dfe; }

#prescreening .prescreening__upload-icon {
  width: 100px;
  height: 60px;
  border: transparent; }

.prescreening-list {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.prescreening__jobStatus--margin-y {
  margin: 5% 0; }

@media screen and (min-height: 731px) {
  .prescreening__jobStatus--margin-y {
    margin: 20% 0; } }

@media screen and (min-height: 812px) {
  .prescreening__jobStatus--margin-y {
    margin: 35% 0; } }

.prescreening__gender--margin-y {
  margin: 30% 0; }

@media screen and (min-height: 731px) {
  .prescreening__gender--margin-y {
    margin: 40% 0; } }

@media screen and (min-height: 812px) {
  .prescreening__gender--margin-y {
    margin: 50% 0; } }

@media screen and (min-width: 768px) {
  .prescreening__gender--margin-y {
    margin: 36% 0; } }

.prescreening__dob--margin-y {
  margin: 50% 0; }

@media screen and (min-height: 731px) {
  .prescreening__dob--margin-y {
    margin: 62% 0; } }

@media screen and (min-height: 812px) {
  .prescreening__dob--margin-y {
    margin: 75% 0; } }

@media screen and (min-width: 768px) {
  .prescreening__dob--margin-y {
    margin: 49% 0; } }

.prescreening__referral--margin-y {
  margin: 65% 0; }

@media screen and (min-height: 731px) {
  .prescreening__referral--margin-y {
    margin: 65% 0; } }

@media screen and (min-height: 812px) {
  .prescreening__referral--margin-y {
    margin: 80% 0; } }

@media screen and (min-width: 768px) {
  .prescreening__referral--margin-y {
    margin: 50% 0; } }

.prescreening__monthly-expenses--margin-y {
  margin: 55% 0; }

@media screen and (min-height: 731px) {
  .prescreening__monthly-expenses--margin-y {
    margin: 65% 0; } }

@media screen and (min-height: 812px) {
  .prescreening__monthly-expenses--margin-y {
    margin: 75% 0; } }

@media screen and (min-width: 768px) {
  .prescreening__monthly-expenses--margin-y {
    margin: 52% 0; } }

/*
 * Desktop View
 */
@media screen and (min-width: 768px) {
  .prescreening .prescreening-progress {
    margin-top: 0 !important; }
    .prescreening .prescreening-progress .progress-detail {
      text-align: right !important; }
  #prescreening .prescreening__ktp-height {
    height: 20vh; } }

@media screen and (min-width: 1024px) {
  #prescreening .prescreening__ktp-height {
    height: 20vh; } }

@media screen and (min-width: 1280px) {
  #prescreening .prescreening__ktp-height {
    height: 37vh; } }

.fgd-screening .fgd-screening-list .icon,
.fgd-screening .fgd-group-list .icon {
  max-width: 24px;
  max-height: 24px;
  align-self: center;
  justify-self: center; }

.fgd-screening .fgd-screening-list .fgd-group-list__value,
.fgd-screening .fgd-group-list .fgd-group-list__value {
  margin: 0;
  align-self: center;
  justify-self: start; }

#fgd-screening .fgd-screening-item {
  margin: 32px 0;
  padding: 0 0 32px;
  border-bottom: 1px solid #edeff2; }
  #fgd-screening .fgd-screening-item .fgd-screening-icon {
    max-width: 42px;
    margin: 0 0 26px; }

#fgd-screening-4 .fgd-screening__icon {
  max-width: 30px; }

#fgd-screening-4 .fgd-screening-header {
  border-bottom: 1px solid #edeff2;
  margin: 32px 0;
  padding-bottom: 16px; }
  #fgd-screening-4 .fgd-screening-header h4 {
    padding-bottom: 10px;
    margin-bottom: 32px;
    border-bottom: 1px solid #edeff2; }

#fgd-screening-4 .study-approval h2 {
  font-size: 0.81rem; }

#fgd-screening-4 .fgd-screening-list {
  margin-top: 30px; }

#fgd-screening-6 .fgd-group {
  padding: 24px;
  box-shadow: 0 0 12px 0 #e6e8ea;
  border-radius: 8px;
  margin-bottom: 24px; }
  #fgd-screening-6 .fgd-group:nth-child(1) {
    margin-top: 32px; }
  #fgd-screening-6 .fgd-group .fgd-group-header {
    border-bottom: 1px solid #edeff2;
    padding-bottom: 42px;
    margin-bottom: 27px;
    text-align: center;
    vertical-align: center; }
    #fgd-screening-6 .fgd-group .fgd-group-header h4 {
      float: left; }
    #fgd-screening-6 .fgd-group .fgd-group-header span {
      float: right; }
    #fgd-screening-6 .fgd-group .fgd-group-header .fgd-group-slot {
      text-transform: uppercase;
      font-size: 0.625rem;
      width: 52px;
      padding: 3px 5px;
      color: #ffffff;
      background-color: #faaf40;
      border-radius: 40px;
      font-weight: 700; }
      #fgd-screening-6 .fgd-group .fgd-group-header .fgd-group-slot.slot--full {
        background-color: #f14336; }
  #fgd-screening-6 .fgd-group .fgd-group-content {
    border-bottom: 1px solid #edeff2;
    padding-bottom: 24px; }

@media screen and (min-width: 1024px) {
  .fgd-screening {
    height: 100%; }
    .fgd-screening .fgd-screening-header {
      margin-top: 0 !important; }
    .fgd-screening .study-approval {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-areas: 'heading .' 'question button' 'option .'; }
      .fgd-screening .study-approval h2 {
        grid-area: heading;
        font-size: 1.25rem !important; }
      .fgd-screening .study-approval label {
        grid-area: question;
        margin: 0; }
      .fgd-screening .study-approval select {
        grid-area: option;
        margin-top: 18px; }
      .fgd-screening .study-approval a {
        grid-area: button;
        justify-self: end;
        align-self: flex-end; }
  #fgd-screening .fgd-screening-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 30px; }
    #fgd-screening .fgd-screening-item a.btn-primary, #fgd-screening .fgd-screening-item a.btn-secondary, #fgd-screening .fgd-screening-item a.btn--orange {
      justify-self: end;
      width: 100%; }
  #fgd-screening-6 .fgd-group-list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 24px; }
    #fgd-screening-6 .fgd-group-list .fgd-group:nth-child(1) {
      margin-top: 0; } }

.studi .tab-study-header {
  margin-top: 32px; }

.studi .study-item {
  margin: 32px 0;
  padding: 0 0 32px;
  border-bottom: 1px solid #edeff2; }

.studi .studi__not-eligible {
  display: flex;
  flex: 1 1;
  align-items: center;
  flex-direction: column; }

.studi__img-wrapper {
  max-width: 75%; }

#studi .tab-study-header .img-lg {
  margin-top: 38px; }

#studi-8 .fa-arrow-left {
  color: #179dfe; }

#studi-8 .study-details {
  margin-top: 40px; }

#studi-9 .survey-header__id {
  display: flex;
  align-items: center;
  margin-top: 46px; }
  #studi-9 .survey-header__id p {
    margin: 0 10px 0 0; }
  #studi-9 .survey-header__id input {
    border: 0px;
    height: 14px;
    width: 150px; }

#studi-9 .survey-header__time {
  margin-top: 20px; }

#studi-9 .survey {
  margin-top: 44px; }

@media screen and (min-width: 768px) {
  .studi {
    height: 100%; }
    .studi .tab-study-header {
      margin-top: 0; }
    .studi .studi__not-eligible {
      display: flex;
      flex: 1 1; }
    .studi__img-wrapper {
      max-width: 30%; }
  #studi-7 .study-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 30px; }
    #studi-7 .study-item a.btn-primary, #studi-7 .study-item a.btn-secondary, #studi-7 .study-item a.btn--orange {
      justify-self: end;
      width: 100%; }
  #studi-8 .study-details {
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
    padding: 32px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'study-icon .'; }
    #studi-8 .study-details .study-icon {
      grid-area: study-icon; }
  #studi-9 .body-content {
    border-top: 1px solid #edeff2; }
  #studi-9 .survey-header__id {
    margin-top: 0 !important; }
  #studi-9 .survey-header__time {
    margin-top: 10px !important;
    float: right !important; } }

.settings {
  margin-bottom: 76px; }
  .settings .settings-header {
    margin-top: 48px;
    border-bottom: 1px solid #edeff2; }
  .settings .profile-settings-form,
  .settings .password-settings-form,
  .settings .notification-settings-form,
  .settings .bank-account-settings-form {
    margin-top: 32px; }
  .settings .notification-settings__update-label {
    color: #faaf40;
    font-weight: 800; }
  .settings .btn,
  .settings .btn-primary,
  .settings .btn-secondary,
  .settings .btn--orange,
  .settings .btn-secondary {
    font-size: 0.925rem; }

#edit-profile .profile-settings-picture {
  display: flex;
  align-items: flex-end;
  margin: 8px 0 32px; }
  #edit-profile .profile-settings-picture button {
    margin: 0;
    padding: 0; }
  #edit-profile .profile-settings-picture .img-avatar__wrapper {
    max-width: 90px;
    max-height: 90px;
    position: relative;
    border-radius: 50%; }
  #edit-profile .profile-settings-picture #profile-avatar {
    max-width: 90px;
    border-radius: 50px; }
  #edit-profile .profile-settings-picture > * {
    margin-right: 1rem; }

#edit-profile .profile-settings-id-card {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  #edit-profile .profile-settings-id-card p {
    margin: 0 0 0 24px; }

#edit-profile .profile-settings-id-card__upload-icon {
  width: 100px;
  height: 60px;
  border: transparent; }

#edit-profile form {
  border-bottom: 1px solid #edeff2; }

#edit-profile .settings-footer {
  display: inline; }
  #edit-profile .settings-footer .btn {
    width: 47%; }

#password .btn-primary, #password .btn-secondary, #password .btn--orange {
  margin: 30px 0 16px; }

#notification h4 {
  margin: 32px 0 16px; }

#bank-account input#bank-account-name,
#bank-account input#bank-account-bank-name,
#bank-account input#bank-account-number {
  margin-bottom: 8px; }

#bank-account #bank-account-bank-branch {
  margin-bottom: 0 !important; }

#account .account-settings-item {
  padding: 32px 0;
  border-bottom: 1px solid #edeff2; }

.bank-account-settings-form {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; }

/*
* Desktop View
*/
@media screen and (min-width: 768px) {
  .settings {
    height: 100%; }
    .settings .btn,
    .settings .btn-primary,
    .settings .btn-secondary,
    .settings .btn--orange,
    .settings .btn-secondary {
      font-size: 1.05rem; }
    .settings .settings-grid {
      display: grid;
      grid-template-columns: 1fr 1fr; }
    .settings .profile-settings-form,
    .settings .password-settings-form,
    .settings .notification-settings-form,
    .settings .bank-account-settings-form {
      margin-top: 0 !important; }
    .settings .bank-account-settings-form__img-wrapper {
      padding: 1rem 25rem; }
  #edit-profile .settings-grid form {
    border: 0; }
  #notification h4 {
    margin-top: 0; }
  #notification .settings-icon {
    width: 50%;
    justify-self: center; }
  #account .settings-grid {
    grid-template-columns: 1fr; }
    #account .settings-grid .account-settings-item:nth-child(1) {
      padding-top: 0; }
    #account .settings-grid .account-settings-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: '. button'; }
      #account .settings-grid .account-settings-item button {
        grid-area: button;
        justify-self: end;
        align-self: flex-end; } }

.img-thumbnail--custom {
  display: inline-block;
  max-width: 100%;
  height: auto;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: all 0.2s ease-in-out; }

.img__md--rounded {
  width: 90px;
  height: 90px;
  border-radius: 50%; }

#signup-1 .img-3-dots {
  margin: 32px 0; }

#signup-1 .signup-header h2 {
  margin-top: 0; }

.signup .body-content {
  margin: 48px 0; }

.signup .signup-icon {
  text-align: center !important;
  justify-self: center !important; }

.signup .signup-header h2 {
  margin-top: 24px; }

.signup .notification-box p {
  background-color: #fff3da;
  padding: 0.75rem 1rem; }

.signup button[type='submit'] {
  margin-bottom: 16px; }

/*
 * Desktop View
 */
@media screen and (min-width: 768px) {
  #signup .signup-list {
    margin-top: 24px; }
    #signup .signup-list .signup-btn-socmed {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 12px; }
  #signup-1 .signup-form .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-template-areas: 'name-first name-last' 'email email' 'password password'; }
    #signup-1 .signup-form .form-group .signup-name-first {
      grid-area: name-first; }
    #signup-1 .signup-form .form-group .signup-name-last {
      grid-area: name-last; }
    #signup-1 .signup-form .form-group .signup-email {
      grid-area: email; }
    #signup-1 .signup-form .form-group .signup-password {
      grid-area: password; } }

.saldo {
  height: 100%; }
  .saldo .balance-item:nth-child(1) {
    padding-top: 0; }
  .saldo .balance-item {
    padding: 24px 0;
    border-bottom: 1px solid #edeff2;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    grid-template-areas: "detail value"; }
    .saldo .balance-item .balance__detail {
      grid-area: detail;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      grid-template-areas: "title title" "date time" "status status"; }
      .saldo .balance-item .balance__detail .balance__title {
        grid-area: title; }
      .saldo .balance-item .balance__detail .balance__date {
        grid-area: date; }
      .saldo .balance-item .balance__detail .balance__time {
        grid-area: time; }
      .saldo .balance-item .balance__detail .balance__status {
        grid-area: status; }
    .saldo .balance-item .balance__value {
      grid-area: value;
      justify-self: end; }
  .saldo .balance__status {
    text-transform: uppercase;
    text-align: center;
    font-size: 0.625rem;
    width: 80px;
    display: flex;
    justify-content: center;
    padding: 3px 5px;
    margin-top: 8px;
    color: #ffffff;
    border-radius: 40px;
    font-weight: 700; }
    .saldo .balance__status.status--success {
      background-color: #3ccd90; }
    .saldo .balance__status.status--pending {
      background-color: #faaf40; }
    .saldo .balance__status.status--rejected {
      background-color: #f14336; }

.active-study .active-study__iframe {
  width: 100%;
  height: 80vh;
  border: 0; }

.active-study .active-study__body {
  border-top: 1px solid #a1afbb;
  grid-area: body;
  padding-top: 1.5rem;
  margin-top: 1.5rem; }

@media screen and (min-width: 768px) {
  .active-study .active-study__body {
    margin-right: 2rem;
    margin-top: 2rem; } }

.thankyou__popcorn-image--mb-negative {
  margin-bottom: -70px; }

@media screen and (min-width: 1280px) {
  .thankyou__popcorn-image {
    width: 25%; } }

.under-maintenance nav.nav-brand {
  padding: 16px 28px;
  background-image: linear-gradient(to right, #ee7824, #ffb808); }
  .under-maintenance nav.nav-brand img {
    width: 100px; }

.under-maintenance .body-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100vh;
  margin: auto; }
  .under-maintenance .body-content .img-wrapper,
  .under-maintenance .body-content .info-wrapper {
    margin: 0 12px;
    width: 48%; }
  .under-maintenance .body-content .img-wrapper {
    width: 400px; }
    .under-maintenance .body-content .img-wrapper img {
      width: 100%; }
  .under-maintenance .body-content .info-wrapper h2 {
    margin-bottom: 18px;
    color: black !important; }

@media screen and (max-width: 768px) {
  .under-maintenance {
    text-align: center; }
    .under-maintenance .body-content {
      display: block;
      width: 100%;
      padding: 0 20px; }
      .under-maintenance .body-content .img-wrapper {
        width: 70%;
        margin: 20px auto; }
      .under-maintenance .body-content .info-wrapper {
        margin: 20px auto;
        width: 100%; } }

span {
  background-color: transparent; }

.App {
  height: 100%; }

#root {
  height: 100%; }

/*
 * Color Styles
 */
html,
body {
  height: 100%; }

:root {
  font-size: 16px; }

.lp-body .lp-body-section > *:not(.lp-header):not(#home--hero) {
  padding-left: 24px;
  padding-right: 24px; }

li {
  list-style-type: none; }

ul {
  padding: 0; }

@media (max-width: 787.98px) {
  .lp-mobile-disabled {
    display: none; } }

/*
* Medium Devices (tablets, 768px and up)
*/
@media (min-width: 768px) {
  .lp-body .lp-body-section > *:not(.lp-header):not(#home--hero):not(#career--cv) {
    padding-top: 48px; }
  .lp-body .lp-body-section > *:not(.lp-header):not(#home--hero) {
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 48px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .lp-tablet-disabled {
    display: none; } }

/*
* Large Devices (desktops, 992px and up)
*/
@media (min-width: 992px) {
  .lp-body .lp-body-section > *:not(.lp-header):not(#home--hero):not(#about--populix-about):not(#career--cv) {
    padding-top: 96px; }
  .lp-body .lp-body-section > *:not(.lp-header):not(#home--hero):not(#about--populix-about) {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 96px; }
  .lp-desktop-disabled {
    display: none; } }

h1.lp-h1, .header--title {
  font-size: 1.5rem;
  line-height: 2rem; }

h3.lp-h3 {
  font-size: 1.125rem; }

h4.lp-h4 {
  font-size: 1rem;
  line-height: 2rem; }

p.lp-p, .header--subtitle {
  font-size: 0.75rem;
  color: #858585; }

.nav-link {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.25rem; }

.text__white {
  color: #FFFFFF !important; }

.text__blue {
  color: #179DFE !important; }

.section--title {
  text-transform: uppercase;
  color: #10496D;
  font-weight: bold;
  line-height: 2rem; }

.btn-secondary {
  font-size: 0.75rem;
  font-weight: 600; }

.lp-header {
  text-align: center; }
  .lp-header .header--title {
    color: #10496D;
    text-transform: uppercase;
    font-weight: bold; }
  .lp-header .header--subtitle {
    color: #858585;
    line-height: 1.125rem;
    margin: 0 !important; }

.form--question {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #858585; }

.form--input,
.form--textarea {
  font-size: 1rem;
  color: #595959; }

.lp-footer .footer--link .link-item a {
  font-size: 0.625rem; }

.lp-footer .footer--copyright .copyright-text {
  font-size: 0.625rem; }

/*
* Medium Devices (tablets, 768px and up)
*/
@media (min-width: 768px) {
  h1.lp-h1, .header--title {
    font-size: 2rem !important;
    line-height: 3rem !important; }
  .header--subtitle {
    font-size: 1.25rem !important;
    line-height: 2rem !important; }
  .header--btn {
    font-size: 1.0625rem !important;
    line-height: 1.625rem !important; }
  h4.lp-h4, .section--title {
    font-size: 1.25rem !important; }
  p.lp-p {
    font-size: 1rem !important;
    line-height: 1.5rem !important; }
  h3.lp-h3 {
    font-size: 1.5rem !important;
    line-height: 2rem !important; }
  .lp-footer .footer--link .link-item a {
    font-size: 0.75rem !important; }
  .lp-footer .footer--copyright .copyright-text {
    font-size: 0.75rem !important; } }

/*
* Large Devices (desktops, 992px and up)
*/
@media (min-width: 992px) {
  p.lp-p {
    line-height: 2rem !important; }
  .lp-footer .footer--link .link-item a {
    font-size: 0.825rem; }
  .lp-footer .footer--copyright .copyright-text {
    font-size: 0.825rem; } }

.lp-navbar,
#lp-menu--tablet-mobile {
  background-color: #FFFFFF;
  border-bottom: 1px solid #EDEFF2 !important;
  padding: 12px 24px !important; }
  .lp-navbar .navbar-brand img,
  #lp-menu--tablet-mobile .navbar-brand img {
    height: auto;
    width: 81px; }
  .lp-navbar .nav-item,
  #lp-menu--tablet-mobile .nav-item {
    margin: 0 0 32px 0; }
    .lp-navbar .nav-item .nav-link,
    #lp-menu--tablet-mobile .nav-item .nav-link {
      color: #179DFE !important;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 1.25rem; }
      .lp-navbar .nav-item .nav-link:hover,
      #lp-menu--tablet-mobile .nav-item .nav-link:hover {
        color: #10496D !important; }
    .lp-navbar .nav-item .active,
    #lp-menu--tablet-mobile .nav-item .active {
      color: #10496D !important; }
    .lp-navbar .nav-item:not(.nav-btn-link) .nav-link,
    #lp-menu--tablet-mobile .nav-item:not(.nav-btn-link) .nav-link {
      padding-left: 0;
      padding-right: 0; }
  .lp-navbar .nav-btn-link,
  #lp-menu--tablet-mobile .nav-btn-link {
    border-radius: 4px !important;
    max-width: 96px; }
    .lp-navbar .nav-btn-link .nav-link,
    #lp-menu--tablet-mobile .nav-btn-link .nav-link {
      color: #FFFFFF !important;
      border-radius: 4px !important;
      text-align: center; }
  .lp-navbar #navbar-btn__login,
  #lp-menu--tablet-mobile #navbar-btn__login {
    background-color: #179DFE; }
  .lp-navbar #navbar-btn__signup,
  #lp-menu--tablet-mobile #navbar-btn__signup {
    background-color: #FAAF40; }
  .lp-navbar #navbar-btn__dashboard,
  #lp-menu--tablet-mobile #navbar-btn__dashboard {
    background-color: #179DFE; }
    .lp-navbar #navbar-btn__dashboard > a,
    #lp-menu--tablet-mobile #navbar-btn__dashboard > a {
      padding-left: 13px !important;
      padding-right: 13px !important; }
  .lp-navbar #navbar-btn__logout,
  #lp-menu--tablet-mobile #navbar-btn__logout {
    background-color: #a1afbb; }

.lp-navbar-wrapper {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid #EDEFF2; }
  .lp-navbar-wrapper .navbar-brand {
    width: 80px !important;
    margin: 5px 5px 5px 24px; }
  .lp-navbar-wrapper .bm-overlay,
  .lp-navbar-wrapper .bm-menu-wrap {
    top: 0 !important; }
  .lp-navbar-wrapper #navbar-btn__dashboard-mobile {
    background-color: #179DFE; }
    .lp-navbar-wrapper #navbar-btn__dashboard-mobile > a {
      padding-left: 13px !important;
      padding-right: 13px !important; }
  .lp-navbar-wrapper .bm-burger-button .bm-burger-bars {
    background-color: #10496D !important;
    height: 2px !important;
    width: 16px !important;
    left: 90vw !important; }
    .lp-navbar-wrapper .bm-burger-button .bm-burger-bars:nth-child(1) {
      top: 16px !important; }
    .lp-navbar-wrapper .bm-burger-button .bm-burger-bars:nth-child(2) {
      top: 23px !important;
      width: 12px !important;
      margin-left: 4px !important; }
    .lp-navbar-wrapper .bm-burger-button .bm-burger-bars:nth-child(3) {
      top: 30px !important; }
  .lp-navbar-wrapper .bm-burger-button button {
    left: 90vw !important;
    top: 16px !important;
    width: 30px !important;
    height: 17px !important; }

@media (min-width: 768px) {
  .lp-navbar-wrapper .navbar-brand {
    margin-left: 40px; }
  .lp-navbar-wrapper .bm-burger-button .bm-burger-bars {
    left: 95vw !important; }
  .lp-navbar-wrapper .bm-burger-button button {
    left: 95vw !important; } }

/*
* Large Devices (desktops, 992px and up)
*/
@media (min-width: 992px) {
  .lp-navbar {
    padding-left: 90px !important;
    padding-right: 90px !important; }
    .lp-navbar .nav-item {
      margin: 0 32px 0 0 !important; }
      .lp-navbar .nav-item:nth-last-child(1) {
        margin-right: 0 !important; }
      .lp-navbar .nav-item:not(.nav-btn-link) .nav-link {
        padding-left: 0;
        padding-right: 0; }
    .lp-navbar .nav-btn-link .nav-link {
      padding: 9px 27px !important;
      text-align: left; } }

.lp-header {
  padding: 48px 0 0;
  background-image: linear-gradient(rgba(245, 251, 255, 0) 40%, #CDE9FD 100%); }
  .lp-header .header--title {
    margin-bottom: 8px;
    padding: 0 24px; }
  .lp-header .header--subtitle {
    margin-bottom: 24px;
    padding: 0 36px; }
  .lp-header .header--btn {
    padding: 8px 25px;
    background-color: #EAB360 !important; }
  .lp-header:not(#home--hero) {
    display: grid;
    align-items: center;
    height: 184px;
    padding: 0; }
  .lp-header--nobg {
    background: none; }

/*
* Medium Devices (tablets, 768px and up)
*/
@media (min-width: 768px) {
  .lp-header {
    padding-top: 96px;
    padding-left: 5%;
    padding-right: 5%; }
    .lp-header .header--btn {
      width: 230px;
      height: 58px; }
    .lp-header:not(#home--hero) {
      height: 288px !important; } }

/*
* Large Devices (desktops, 992px and up)
*/
@media (min-width: 992px) {
  .lp-header {
    padding: 0; }
    .lp-header:not(#home--hero) {
      height: 280px !important; } }

.lp-footer {
  color: #858585;
  display: grid; }
  .lp-footer .footer--link {
    border-top: solid 1px #EDEFF2;
    padding: 30px; }
    .lp-footer .footer--link ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 8px;
      margin: 0;
      padding-left: 20%; }
      .lp-footer .footer--link ul .link-item {
        list-style-type: none; }
        .lp-footer .footer--link ul .link-item a {
          color: #858585; }
  .lp-footer .footer--copyright {
    border-top: solid 1px #EDEFF2;
    text-align: center;
    padding: 12px 8px;
    justify-content: space-around; }
    .lp-footer .footer--copyright .copyright-text {
      color: #858585;
      margin: 0; }

/*
* Medium Devices (tablets, 768px and up)
*/
@media (min-width: 768px) {
  .lp-footer {
    display: grid;
    padding: 24px 30px;
    border-top: 1px solid #EDEFF2;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'footer-copyright footer-link'; }
    .lp-footer .footer--link {
      grid-area: footer-link;
      padding: 0;
      justify-self: flex-end;
      max-width: 450px;
      border-top: 0; }
      .lp-footer .footer--link ul {
        padding: 0;
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-column-gap: 32px; }
    .lp-footer .footer--copyright {
      grid-area: footer-copyright;
      padding: 0;
      border-top: 0; }
      .lp-footer .footer--copyright .copyright-text {
        margin: 0; }
  .lp-footer__social-media-button {
    cursor: pointer; } }

/*
* Large Devices (desktops, 992px and up)
*/
@media (min-width: 992px) {
  .lp-footer {
    padding: 32px; } }

.lp-btn.btn-primary, .lp-btn.btn-secondary, .lp-btn.btn--orange {
  border: 0;
  background-color: #179DFE; }

.lp-btn.btn-secondary {
  background-color: #FAAF40;
  border: 0;
  color: #FFFFFF;
  width: 150px;
  height: 40px;
  margin: 0 !important; }

.slick-slider .slick-arrow {
  display: none !important; }

.notification_box {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  z-index: 999;
  color: #000000;
  font-weight: 400; }
  .notification_box.notification_box--success {
    background-color: #fff3da; }
  .notification_box.notification_box--failed {
    background-color: #d9aaaa;
    color: white; }

@media screen and (max-width: 768px) {
  .notification_box {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    bottom: 0;
    left: 0; } }

.spinner-ripple__container {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px; }
  .spinner-ripple__container .spinner-ripple__child {
    position: absolute;
    border: 4px solid #faaf40;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: spinner-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: spinner-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .spinner-ripple__container .spinner-ripple__child:nth-child(2) {
      -webkit-animation-delay: 0.5s;
              animation-delay: 0.5s;
      border: 4px solid #179DFE; }

.spinner-roller__container {
  display: inline-block;
  position: relative;
  width: 0px;
  height: 0px;
  top: -23px; }
  .spinner-roller__container .spinner-roller__child {
    position: absolute;
    width: 3.5px;
    height: 3.5px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: spinner-roller 2s linear infinite;
            animation: spinner-roller 2s linear infinite; }
    .spinner-roller__container .spinner-roller__child:nth-child(1) {
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
      top: 30px;
      left: 29px; }
    .spinner-roller__container .spinner-roller__child:nth-child(2) {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s;
      top: 24px;
      left: 40px; }
    .spinner-roller__container .spinner-roller__child:nth-child(3) {
      -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s;
      top: 10px;
      left: 40px; }
    .spinner-roller__container .spinner-roller__child:nth-child(4) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s;
      top: 6px;
      left: 29px; }
    .spinner-roller__container .spinner-roller__child:nth-child(5) {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s;
      top: 10px;
      left: 18px; }
    .spinner-roller__container .spinner-roller__child:nth-child(6) {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s;
      top: 24px;
      left: 18px; }
    .spinner-roller__container .spinner-roller__child:nth-child(7) {
      -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s;
      top: 18px;
      left: 29px; }
    .spinner-roller__container .spinner-roller__child:nth-child(8) {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s;
      top: 41px;
      left: 9px; }
    .spinner-roller__container .spinner-roller__child:nth-child(9) {
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s;
      top: 50px;
      left: 18px; }
    .spinner-roller__container .spinner-roller__child:nth-child(10) {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s;
      top: 53px;
      left: 29px; }
    .spinner-roller__container .spinner-roller__child:nth-child(11) {
      -webkit-animation-delay: -1s;
              animation-delay: -1s;
      top: 50px;
      left: 41px; }
    .spinner-roller__container .spinner-roller__child:nth-child(12) {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s;
      top: 41px;
      left: 50px; }

@-webkit-keyframes spinner-ripple {
  0% {
    top: 22px;
    left: 22px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 47px;
    height: 47px;
    opacity: 0; } }

@keyframes spinner-ripple {
  0% {
    top: 22px;
    left: 22px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 47px;
    height: 47px;
    opacity: 0; } }

@-webkit-keyframes spinner-roller {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0f5c80; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    background-color: #fdbc50; } }

@keyframes spinner-roller {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #0f5c80; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    background-color: #fdbc50; } }

.react-datepicker-wrapper {
  display: block;
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
    .react-datepicker-wrapper .react-datepicker__input-container .prescreening__option {
      border-bottom: 1px solid;
      color: #179dfe;
      font-weight: 700; }
      @media screen and (min-width: 961px) {
        .react-datepicker-wrapper .react-datepicker__input-container .prescreening__option {
          border-bottom: transparent; } }
      .react-datepicker-wrapper .react-datepicker__input-container .prescreening__option::-webkit-input-placeholder {
        color: #179dfe;
        font-weight: 700; }
      .react-datepicker-wrapper .react-datepicker__input-container .prescreening__option::placeholder {
        color: #179dfe;
        font-weight: 700; }

.react-datepicker-popper .react-datepicker__header {
  background-color: transparent;
  border-bottom: 0; }
  .react-datepicker-popper .react-datepicker__header .react-datepicker__current-month {
    display: none; }

.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #faaf40; }
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #f9e4b8;
    border-radius: 50%;
    color: #10496D; }

.react-datepicker__day:hover {
  border-radius: 50%; }

.react-datepicker__navigation.react-datepicker__navigation--next {
  display: none; }

.react-datepicker__navigation.react-datepicker__navigation--previous {
  display: none; }

.react-datepicker__month-select {
  font-size: 1.333em; }

.react-datepicker__year-select {
  font-size: 1.333em; }

.react-datepicker__header__dropdown--select {
  padding-bottom: 0.5em; }

.react-datepicker__triangle {
  border-bottom-color: #ffffff !important; }

.react-datepicker__day-name {
  font-weight: bold;
  color: #546e7e; }

.react-datepicker__day--selected {
  background-color: #faaf40;
  font-weight: bold; }

.study-card-detail.study-card-detail__base {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08); }

.rangeslider-container {
  max-width: 80%; }

.rangeslider,
.rangeslider {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.4) !important; }
  .rangeslider.rangeslider-horizontal,
  .rangeslider.rangeslider-horizontal {
    height: 6px; }
    .rangeslider.rangeslider-horizontal .rangeslider__fill,
    .rangeslider.rangeslider-horizontal .rangeslider__fill {
      background-color: #179dfe;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.4) !important; }
    .rangeslider.rangeslider-horizontal .rangeslider__handle,
    .rangeslider.rangeslider-horizontal .rangeslider__handle {
      width: 20px;
      height: 20px; }
      .rangeslider.rangeslider-horizontal .rangeslider__handle:after,
      .rangeslider.rangeslider-horizontal .rangeslider__handle:after {
        width: 10px;
        height: 10px;
        top: 4px;
        left: 3.8px;
        background-color: #fdbc50;
        box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.4) inset, 0 0px 2px rgba(0, 0, 0, 0.4) inset; }

